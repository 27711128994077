import React from "react";
import * as styles from "./subtitle.module.scss";

interface Props {
  content: string;
}

export const Subtitle: React.FC<Props> = ({ content }) => {
  return <div className={styles.text}>{content}</div>;
};
