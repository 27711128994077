import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { mapStyles } from "./mapStyles";
import { clusterStyles } from "./clusterStyles";
import { SetBounds } from "./setBounds";
import { PanTo } from "./panTo";
import * as styles from "./map.module.scss";
// import XorusPin from "../../../static/Xorus_try2.svg";

interface Props {
  coords: any;
  panCoords: any;
  boundsCoords: any;
  myPos?: any;
}

declare global {
  interface Window {
    google: any;
  }
}

export const Map: React.FC<Props> = React.memo(
  ({ coords, panCoords, boundsCoords, myPos }) => {
    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: "AIzaSyBBmyP4NoEqD1RsF7Kkxir4uWX-_nqJnYA",
      // ...otherOptions
    });

    const renderMap = () => {
      // wrapping to a function is useful in case you want to access `window.google`
      // to eg. setup options or create latLng object, it won't be available otherwise
      // feel free to render directly if you don't need that

      return (
        <GoogleMap
          onLoad={(mapInstance) => {
            const bounds = new window.google.maps.LatLngBounds();
            boundsCoords &&
              boundsCoords.map((item: any) => {
                // locations.map((item: any) => {
                bounds.extend(item);
              });
            mapInstance.fitBounds(bounds);
          }}
          zoom={4}
          options={{
            panControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            zoomControl: true,
            fullscreenControl: false,
            styles: mapStyles,
            backgroundColor: "#202023",
            maxZoom: 15,
          }}
          mapContainerStyle={{
            height: "100%",
          }}
        >
          {!panCoords && <SetBounds coords={boundsCoords} />}
          {/* <SetBounds coords={boundsCoords} /> */}
          {panCoords && (
            <>
              <SetBounds coords={[panCoords]} />
              <PanTo panCoords={panCoords} />
            </>
          )}
          {coords && (
            <>
              {myPos && (
                <Marker
                  key={createKey(myPos)}
                  position={myPos}
                  icon="/POI-concave.svg"
                />
              )}
              <MarkerClusterer
                styles={clusterStyles}
                clusterClass={styles.customClustericon}
              >
                {(clusterer) =>
                  coords &&
                  coords.map((location: any) => (
                    //   locations.map((location: any) => (
                    <Marker
                      key={createKey(location)}
                      position={location}
                      label={{
                        text: location.name,
                        color: "#009c51",
                        className: `${styles.label}`,
                      }}
                      clusterer={clusterer}
                      icon="/POI-concave.svg"
                    />
                  ))
                }
              </MarkerClusterer>
            </>
          )}
        </GoogleMap>
      );
    };

    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : null;
  }
);

function createKey(location: any) {
  return location.lat + location.lng;
}
