import { useGoogleMap } from "@react-google-maps/api";
import React, { useEffect } from "react";

interface Props {
  panCoords: any;
}
export const PanTo: React.FC<Props> = ({ panCoords }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (map) {
      map.panTo(panCoords);
      map.setZoom(11);
    }
  }, [panCoords, map]);
  return null;
};
