import React, { useState } from "react";
import { Layout } from "../components/Layout/Layout";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { SEO } from "../components/Seo";
import { Subtitle } from "../components/Subtitle/Subtitle";
import * as styles from "../styles/pages/shops.module.scss";
import { Map } from "../components/Map/Map";
import { usePageContext } from "../components/Context/pageContext";
import { MapModule } from "../modules/Map/MapModule";
import { graphql } from "gatsby";
import { ShopsPageQuery } from "../../graphql-types";

interface Props {
  data: ShopsPageQuery;
}

const shops: React.FC<Props> = ({ data }) => {
  const { page } = data;
  const { lang } = usePageContext();

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata} />
      <div className={styles.wrapper}>
        <PageTitle content={page?.content?._rawTitle?.[lang]} />
        <Subtitle content={page?.content?._rawSubtitle?.[lang]} />
        <MapModule />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ShopsPage {
    page: sanityShopsPage {
      content {
        _rawTitle
        _rawSubtitle
        _rawZipPlaceholder
        _rawSearchButton
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }
  }
`;

export default shops;
