import { useGoogleMap } from "@react-google-maps/api";
import React, { useEffect } from "react";

interface Props {
  coords: any;
}
export const SetBounds: React.FC<Props> = ({ coords }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      coords &&
        coords.map((item: any) => {
          bounds.extend(item);
        });

      map.fitBounds(bounds);
    }
  }, [map, coords]);
  return null;
};
