import React, { useState } from "react";
import { usePageContext } from "../../components/Context/pageContext";
import { Map } from "../../components/Map/Map";
import * as styles from "./mapModule.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";
import { graphql, useStaticQuery } from "gatsby";
import { ShopsQuery } from "../../../graphql-types";

interface Props {}

export const MapModule: React.FC<Props> = () => {
  const data = useStaticQuery<ShopsQuery>(query);
  const shops = data.allSanityShop.edges;
  const [filter, setFilter] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [panCoords, setPanCoords] = useState<any>(undefined);
  const [geoloc, setGeoloc] = useState<any>(undefined);

  const { lang } = usePageContext();
  const handleAroundme = () => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        setPanCoords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          bool: !panCoords?.bool || true,
        });
        setGeoloc({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => console.log(`error`, err)
      // { timeout: 20000, enableHighAccuracy: true }
    );
  };

  const filteredShops = shops?.filter((shop: any) => {
    return shop.node.zipCode.startsWith(filter);
  });

  const bounds = filteredShops?.map((shop: any) => ({
    lat: shop.node.coords.lat,
    lng: shop.node.coords.lng,
  }));

  const coords = shops.map((shop: any) => ({
    lat: shop.node.coords.lat,
    lng: shop.node.coords.lng,
    name: shop.node.name,
  }));

  const handleClick = () => {
    const element = document.getElementById("navvMenu");
    if (element) element.classList.toggle("inactive");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mapContainer}>
        <Map
          coords={coords}
          panCoords={panCoords}
          boundsCoords={bounds?.length ? bounds : coords}
          myPos={geoloc}
        />
      </div>
      <div className={styles.filterContainer}>
        <div className={styles.aroundFormWrapper}>
          <button
            className={`${styles.aroundmeWrapper} noStyle`}
            onClick={handleAroundme}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} color={"#FFFFFF"} />
          </button>

          <form
            name="filterForm"
            method="post"
            className={styles.formWrapper}
            onSubmit={(e: any) => {
              e.preventDefault();
              // const form = e.target;
              // const data = new FormData(form);
              setPanCoords(undefined);
              setFilter(input);
              // setPanCoords(undefined);
            }}
          >
            <div className={styles.inputContainer}>
              <input
                type="text"
                aria-label="Zip code"
                // placeholder={settings.placeholder[lang]}
                placeholder="placeholder"
                name="searchFilter"
                autoComplete="off"
                className={styles.filterInput}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              {filter && (
                <div className={styles.clearContainer}>
                  <span
                    onClick={() => {
                      setInput("");
                      setFilter("");
                    }}
                  >
                    &times;
                  </span>
                </div>
              )}
              <div style={{ display: "flex", margin: "0 auto" }}>
                <button type="submit" className={styles.filterButton}>
                  Search
                  {/* {settings.search[lang]} */}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className={styles.shopList}>
          {filteredShops?.length ? (
            filteredShops.map((shop: any, index: number) => (
              <div
                className={styles.shopContainer}
                onClick={() =>
                  setPanCoords({
                    lat: shop.node.coords.lat,
                    lng: shop.node.coords.lng,
                  })
                }
                key={`shop-010gksl-${index}`}
              >
                <div className={styles.shopTitle}>{shop.node.name}</div>
                <div className={styles.shopInfo}>{shop.node.address}</div>
                {shop.node.phoneNumber && (
                  <div
                    className={styles.shopInfo}
                  >{`Phone: ${shop.node.phoneNumber}`}</div>
                )}
                {shop.node.url && (
                  <div
                    className={styles.shopInfo}
                  >{`web: ${shop.node.url}`}</div>
                )}
              </div>
            ))
          ) : (
            <div className={styles.shopInfo}>
              Error message
              {/* {settings.errorMessage[lang]} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query Shops {
    allSanityShop {
      edges {
        node {
          id
          name
          phoneNumber
          address
          url
          zipCode
          coords {
            lat
            lng
          }
        }
      }
    }
  }
`;
